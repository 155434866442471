import Typewriter from "typewriter-effect";

function Hero() {
  return (
    <>
      <div className="hero">
        <div className="hero-text-wrapper">
          <div className="hero-heading">Hackdays @Sellular</div>
          <div className="hero-subheading typewriter">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("48 Hours Development Hackathon")
                  .pauseFor(1000)
                  .start();
              }}
            />
            <div className="hero-subheading-2">
              Slot 1: 10th | 11th | 12th March 2023
            </div>
            <div className="hero-subheading-2">
              Slot 2: 17th | 18th | 19th March 2023
            </div>
            <div className="hero-subheading-2">
              Slot 3: 24th | 25th | 26th March 2023
            </div>
          </div>
        </div>
        <a className="hero-register-btn-wrapper" href="#registration">
          <button className="hero-register-btn">Register Now!</button>
        </a>
        <div className="hero-subtext">
          For You to{" "}
          <span className="fade-wrapper">
            <span className="fade-1">Learn</span> |{" "}
            <span className="fade-2">Develop</span> |{" "}
            <span className="fade-3">Implement</span> |{" "}
            <span className="fade-4">Win</span>
          </span>
        </div>
        <section className="rw-wrapper">
          <h2 className="rw-sentence">
            <span>For You to</span>
            <div className="rw-words rw-words-1">
              <span>Learn</span>
              <span>Develop</span>
              <span>Implement</span>
              <span>Win</span>
            </div>
          </h2>
        </section>
      </div>
    </>
  );
}

export default Hero;
