function Awards() {
  return (
    <>
      <div id="cool-rewards" className="awards-wrapper">
        <div className="awards-heading">Cool Rewards</div>
        <div className="awards-cards-wrapper">
          <div className="awards-card">
            <img
              className="awards-card-logo"
              src="purse.gif"
              alt="sellular-logo"
            />
            <div className="awards-card-text">₹20K+ in Cash Prizes</div>
          </div>
          <div className="awards-card">
            <img
              className="awards-card-logo"
              src="award.gif"
              alt="sellular-logo"
            />
            <div className="awards-card-text">
              Goodies on Every Approved Submission
            </div>
          </div>
          <div className="awards-card">
            <img
              className="awards-card-logo"
              src="target.gif"
              alt="sellular-logo"
            />
            <div className="awards-card-text">Mentorship Support</div>
          </div>
        </div>
        <div className="awards-card-hero-text">
          Total Prize Pool &nbsp; &nbsp;
          <span>
            ₹<span>1,00,000+</span>
          </span>
        </div>
        <div className="awards-cards-wrapper">
          <div className="awards-card">
            <img
              src="certificate.gif"
              alt="certificate"
              className="awards-card-logo"
            />
            <div className="awards-card-text">Certificates</div>
          </div>
          <div className="awards-card">
            <img
              src="internship.gif"
              alt="internship"
              className="awards-card-logo"
            />
            <div className="awards-card-text">Internships</div>
          </div>
          <div className="awards-card">
            <img src="passes.gif" alt="passes" className="awards-card-logo" />
            <div className="awards-card-text">DevMonth Passes</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Awards;
