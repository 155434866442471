import React from "react";
// import copy from "react-copy-to-clipboard";
import { FaTimes } from "react-icons/fa";
const Popup = (props) => {
  // const [copyText,setCopyText] = useState('');
  // const handelCopyText = (e)=>{
  //     setCopyText(e.target.value);
  // }
  //   const [customstyle, setCustomstyle] = useState({ filter: "none" });
  let customstyle = { filter: "none" };
  // console.log(props.upibtn);
  if (props.upibtn === false) {
    // setCustomstyle({ filter: "invert()" });
    if (window.matchMedia("(max-width: 700px)").matches) {
      customstyle = { filter: "invert()", width: "40vw", height: "auto" };
    } else {
      customstyle = { filter: "invert()", width: "10vw", height: "auto" };
    }
  } else {
    customstyle = { filter: "none" };
  }
  const closeModal = () => {
    props.closeModal(false);
  };
  const copyToClipboard = () => {
    // copy(upiid);
    const copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    //   alert("Copied the text: " + copyText.value);
  };
  return (
    <div className="Payment-Modal">
      <button onClick={closeModal} className="crossbtn">
        <FaTimes />
      </button>
      <div className="payment-modal-wrapper">
        <img src={props.images} style={customstyle} alt="" />
        <span>{props.message}</span>
        <input type="text" defaultValue="8603018935@okbizicici" id="myInput"></input>
        {props.upibtn && (
          <button className="payment-modal-btn" onClick={copyToClipboard}>
            Copy UPI ID
          </button>
        )}
        {props.okbtn && (
          <button className="payment-modal-btn" onClick={props.okFunc}>
            OK
          </button>
        )}
      </div>
    </div>
  );
};

export default Popup;
