/* eslint-disable */

import React, { useState } from "react";
import "./styles.css";
import axios from "axios";
import Popup from "./Popup";
import { Link } from "react-router-dom";

export default function RegistrationForm() {
  const params = new URLSearchParams(window.location.search);
  //Function for toggle the checkbox button
  let source = params.get("utm_source");
  let medium = params.get("utm_medium");
  let coupon = params.get("utm_coupon");
  // console.log(source, medium, coupon);
  const [email, setEmail] = useState("");
  // const [slotNumber,setSlotNumber]=useState();

  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    collegeName: "",
    description: "",
    LinkedInProfile: "",
    slotNumber: 0,
    fromCoupon : coupon,
  });
  const [popx, setPopx] = useState(false);
  const [upibtn, setUpibtn] = useState(false);
  const [okbtn, setOkbtn] = useState(false);
  const [PopUp, setPopUp] = useState({
    message: "",
    image: "",
    button: "",
  });
  const checkStatus = async () => {
    if (email) {
      const res = await axios.post(
        "https://api.sellular.club/payment/verification-status",
        { email: email }
      );
      if (res.data.status === "OK") {
        await setPopx(true);
        setPopUp({
          message:
            "Successfully Verified. Check Mail for further Communication.",
          image: "./success.gif",
        });
        setUpibtn(false);
      }
      const el2 = document.createElement("div");
      el2.innerHTML = `<div><img src="./gpayqr.jpeg" alt="gpayqr" class="gpayqr"></img></div>`;
      if (res.data.status === "Not Verified") {
        await setPopx(true);
        setPopUp({
          message:
            "Your payment is not verified. Please complete the payment if you have not already.",
          image: "./gpayqr.jpeg",
        });
        setUpibtn(true);
      }
    }
  };
  const submit = async () => {
    console.log(user);
    if (
      (user.name === "") |
      (user.email === "") |
      (user.description === "") |
      (user.phone === "") |
      (user.collegeName === "") |
      (user.slotNumber === 0)
    ) {
      await setPopx(true);
      setPopUp({
        message: "All Fields are Required",
        image: "./warning.gif",
      });
      setUpibtn(false);
      return;
    } else {
      const data = {
        name: user.name,
        email: user.email,
        phoneNumber: user.phone,
        collegeName: user.collegeName,
        description: user.description,
        LinkedInProfile: user.LinkedInProfile,
        slotNumber: user.slotNumber,
        fromCoupon : coupon,
      };
      console.log(data);
      const resp = await axios.post(
        "https://api.sellular.club/payment/register",
        data
      );
      if (source && medium && coupon) {
        await axios
          .post("https://api.sellular.club/payment/utm-link-increment", {
            source,
            medium,
            coupon,
          })
          .then(() => {
            console.log("incremented utm");
          })
          .catch((err) => {
            console.log(err);
          });
      }
      console.log(resp.data.status);
      // console.log(resp.data.message);
      if (resp.data.status === "OK") {
        setPopx(true);
        setPopUp({
          message: resp.data.message,
          image: "./success.gif",
        });
        setUpibtn(false);
        setOkbtn(true);
      } else if (resp.data.status === "Error") {
        await setPopx(true);
        setPopUp({
          message: resp.data.message,
          image: "./warning.gif",
        });
        setUpibtn(false);
      } else if (resp.data.status === undefined) {
        await setPopx(true);
        setPopUp({
          message: "Already Registered",
          image: "./warning.gif",
        });
        setUpibtn(false);
      }
    }
  };
  const okFunc = () => {
    setPopx(true);
    setPopUp({
      message: "Please complete your payment.",
      image: "./gpayqr.jpeg",
    });
    setUpibtn(true);
    setOkbtn(false);
  };
  return (
    <>
      {popx && (
        <div className="PopupContainer">
          <Popup
            images={PopUp.image}
            closeModal={setPopx}
            message={PopUp.message}
            upibtn={upibtn}
            okbtn={okbtn}
            okFunc={okFunc}
          />
        </div>
      )}
      <div className="register-and-check-wrapper">
        <div id="registration" className="registration-form-wrapper">
          <div className="registration-form-heading">Register</div>
          <div className="registration-form">
            <input
              type="text"
              placeholder="Name"
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
            />
            <input
              type="email"
              placeholder="E-mail ID"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <input
              type="number"
              placeholder="Phone Number"
              value={user.phone}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
            />
            <input
              type="text"
              placeholder="College Name"
              value={user.collegeName}
              onChange={(e) =>
                setUser({ ...user, collegeName: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="LinkedIn Profile Link"
              value={user.LinkedInProfile}
              onChange={(e) =>
                setUser({ ...user, LinkedInProfile: e.target.value })
              }
            />
            <div className="checkBox-Container">
              <div className="content">
                <label className="checkBox">
                  <input
                    type="radio"
                    name="inlineRadioOptions"
                    id="option1"
                    onChange={(e) => setUser({ ...user, slotNumber: 1 })}
                  />
                  <div className="transition"></div>
                </label>
              </div>
              <label htmlFor="option1">Slot 1- 10th,11th and 12th March</label>
            </div>
            <div className="checkBox-Container">
              <div className="content">
                <label className="checkBox">
                  <input
                    type="radio"
                    name="inlineRadioOptions"
                    id="option2"
                    onChange={(e) => setUser({ ...user, slotNumber: 2 })}
                  />
                  <div className="transition"></div>
                </label>
              </div>
              <label htmlFor="option2">Slot 2- 17th,18th and 19th March</label>
            </div>
            <div className="checkBox-Container">
              <div className="content">
                <label className="checkBox">
                  <input
                    type="radio"
                    name="inlineRadioOptions"
                    id="option3"
                    onChange={(e) => setUser({ ...user, slotNumber: 3 })}
                  />
                  <div className="transition"></div>
                </label>
              </div>
              <label htmlFor="option3">Slot 3- 24th,25th and 26th March</label>
            </div>
            <label className="registration-why-question">
              Why do you want to join the hackathon?
            </label>
            <textarea
              className="registration-why-answer"
              type="text"
              value={user.description}
              onChange={(e) =>
                setUser({ ...user, description: e.target.value })
              }
            />
            <button className="registration-btn" onClick={submit}>
              <a>Register @₹99</a>
            </button>
          </div>
        </div>
        <div>
          <div className="check-status-container">
            <div className="check-status-wrapper">
              <div className="check-status-heading">
                Check your registration status
              </div>
              <input
                type="email"
                placeholder="E-mail ID"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div className="check-status-btn-status-wrapper">
                <button className="check-status-btn" onClick={checkStatus}>
                  Check
                </button>
              </div>
              <div className="TeamRegister">
                <div className="TeamRegister-Heading">Register as a team</div>
                <Link to="/TeamRegister" className="TeamRegister-Link">
                  Register Your Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
