import RegistrationForm from "./RegistrationForm";
import CheckStatus from "./CheckStatus";
function RegisterAndCheck() {
  return (
    <>
      <div>
        <RegistrationForm />
        {/* <CheckStatus /> */}
      </div>
    </>
  );
}

export default RegisterAndCheck;
